<template>
  <div>
    <v-card>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- Search -->
        <v-text-field
        v-model="searchReport"
        :append-icon="icons.mdiMagnify"
        placeholder="Buscar informe"
        outlined
        hide-details
        @keyup="handleSearch"
        dense
        class="me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>
        <v-select
          v-model="statusFilter"
          style="width: 0px"
          class="mb-4"
          :items="[
            { name: 'Todos', value: -1 },
            { name: 'Pendiente', value: 0 },
            { name: 'Procesando', value: 1 },
            { name: 'Finalizado', value: 2 },
          ]"
          hide-details="true"
          label="Estado"
          item-text="name"
          item-value="value"
          @change="getReports()"
          outlined
          dense
        ></v-select>


      </v-card-text>
      <v-data-table
      :headers="tableColumns"
      :items="reportsListTable"
      :search="searchReport"
      :page="current_page"
      :server-items-length="totalReportsListTable"
      :loading="loading"
      @update:page="changingReportsPage($event)"
      @update:items-per-page="changingItemsPerPage($event)"
      :footer-props="{
          'items-per-page-options': [
            10,
            20,
            50,
          ],
          'items-per-page-text': 'Items por página',
          'page-text': '{0}-{1} de {2}',
        }"
      >
        <template v-slot:item.sku="{item}">
          <span class="sku-container">{{item.sku}}</span>
        </template>

        <template v-slot:item.created_at="{item}">
          <span class="date-container">{{item.created_at | formatShowingDate}}</span>
        </template>

        <template v-slot:item.status="{item}">
					<v-chip
						small
						:color="(item.status && item.status.id === 0) ? 'primary' : (item.status && item.status.id === 1) ? 'accent' : 'success'"
						:class="(item.status && item.status.id === 0)  ? 'primary--text' : (item.status && item.status.id === 1)  ? 'accent--text' : 'success--text'"
						class="v-chip-light-bg"
					>
						{{ item.status.label}}
					</v-chip>
				</template>

        <template v-slot:item.report="{item}">
					<v-btn 
						icon 
            @click="getRequestAndOpen(item)"
					>
						<v-icon class="primary--text">
							{{ icons.mdiClipboardEditOutline }}
						</v-icon>
					</v-btn>
            </template>
      </v-data-table>
    </v-card>
    <report-form-modal ref="report_modal"
    @refresh-list="getReports"
    />
  </div>
</template>

<script>
import debounce from "../../plugins/debounce.js";
import { ref } from '@vue/composition-api';
import ReportFormModal from '@/components/Reports/ReportFormModal'
import {
  mdiClipboardEditOutline,
  mdiMagnify
} from '@mdi/js'

export default {
  data: () => {
    return {
      current_page: 1,
      page_size: 10,
      searchReport: '',
      count: 0,
      statusFilter: -1
    }
  },
  filters: {
    formatShowingDate(value) {
      if (!value) return '-';

      const [year, month, day] = value.split('-')
      return `${day}/${month}/${year}`
    },
  },
  methods: {
    handleSearch: debounce(function () {
      this.current_page = 1;
      this.getReports();
    }, 400),
    changingReportsPage(e) {
        this.current_page = e
        this.getReports()
    },
    changingItemsPerPage(e) {
        this.page_size = e
        this.getReports()
    },
    async getReports() {
      this.loading = true
      try {
        const query = {
          page_size: this.page_size,
          page: this.current_page,
          summary: true,
          search: this.searchReport,
          status: this.statusFilter
        }
        const response = await this.$api.getReports(query)
        this.reportsListTable = response.results
        // this.reportsListTable.status = 
        this.totalReportsListTable = response.count
      } catch(e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getRequestAndOpen(item) {
      try {
        const response = await this.$api.getRequest(item.request.id)
        const data = {
          reportId: item.id,
          request: response.data
        }
        this.$refs.report_modal.openReportModal(data)
      } catch(e) {
        console.log(e)
      }
    }
  },
  mounted() {
    // this.getStatus()
    this.getReports()
  },
  setup() {
    const totalReportsListTable = ref(0);
    const reportsListTable = ref([]);
    
    const tableColumns = [
      { text: 'SKU', value: 'sku', align: 'left', sortable: false },
      { text: 'FECHA DE CREACION', value: 'created_at', align: 'center', sortable: false },
      { text: 'ESTADO', value: 'status', align: 'center', sortable: false },
      { text: 'COMPLETAR INFORME', value: 'report', align: 'center', sortable: false },
    ]
    
    const loading = ref(false);
    
    return {
      loading,
      totalReportsListTable,
      reportsListTable,
      tableColumns,
      icons: {
        mdiClipboardEditOutline,
        mdiMagnify
      }
    }
  },
  components: {
    ReportFormModal
  },
}
</script>

<style scoped>
.date-container {
  font-weight: 500;
  color: rgb(125, 125, 125);
}

.sku-container {
  font-weight: 600;
  color: rgb(115, 115, 115);
}
</style>