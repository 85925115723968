<template>
  <div>
    <v-card>
      <v-card-title>Informes</v-card-title>
      <list-reports></list-reports>
    </v-card>
  </div>
</template>

<script>
import ListReports from '@/components/Reports/ListReports';
  export default {
    components:{
      ListReports
    }
    
  }
</script>