import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VTextField,{staticClass:"me-3 mb-4",attrs:{"append-icon":_vm.icons.mdiMagnify,"placeholder":"Buscar informe","outlined":"","hide-details":"","dense":""},on:{"keyup":_vm.handleSearch},model:{value:(_vm.searchReport),callback:function ($$v) {_vm.searchReport=$$v},expression:"searchReport"}}),_c(VSpacer),_c(VSelect,{staticClass:"mb-4",staticStyle:{"width":"0px"},attrs:{"items":[
            { name: 'Todos', value: -1 },
            { name: 'Pendiente', value: 0 },
            { name: 'Procesando', value: 1 },
            { name: 'Finalizado', value: 2 } ],"hide-details":"true","label":"Estado","item-text":"name","item-value":"value","outlined":"","dense":""},on:{"change":function($event){return _vm.getReports()}},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.reportsListTable,"search":_vm.searchReport,"page":_vm.current_page,"server-items-length":_vm.totalReportsListTable,"loading":_vm.loading,"footer-props":{
          'items-per-page-options': [
            10,
            20,
            50 ],
          'items-per-page-text': 'Items por página',
          'page-text': '{0}-{1} de {2}',
        }},on:{"update:page":function($event){return _vm.changingReportsPage($event)},"update:items-per-page":function($event){return _vm.changingItemsPerPage($event)}},scopedSlots:_vm._u([{key:"item.sku",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"sku-container"},[_vm._v(_vm._s(item.sku))])]}},{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"date-container"},[_vm._v(_vm._s(_vm._f("formatShowingDate")(item.created_at)))])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c(VChip,{staticClass:"v-chip-light-bg",class:(item.status && item.status.id === 0)  ? 'primary--text' : (item.status && item.status.id === 1)  ? 'accent--text' : 'success--text',attrs:{"small":"","color":(item.status && item.status.id === 0) ? 'primary' : (item.status && item.status.id === 1) ? 'accent' : 'success'}},[_vm._v(" "+_vm._s(item.status.label)+" ")])]}},{key:"item.report",fn:function(ref){
        var item = ref.item;
return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.getRequestAndOpen(item)}}},[_c(VIcon,{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.icons.mdiClipboardEditOutline)+" ")])],1)]}}])})],1),_c('report-form-modal',{ref:"report_modal",on:{"refresh-list":_vm.getReports}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }